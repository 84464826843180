import React from "react"
import Helmet from "react-helmet"
import TransitionLink from "gatsby-plugin-transition-link"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./404.scss"

const NotFoundPage = ({ transitionStatus }) => [
  <Helmet
    bodyAttributes={{
      class: "page fourOhFour",
    }}
    key="helmet"
  />,
  <Layout>
    <SEO title="Page not found" />
    <h1>Not found</h1>
    <p>Sorry, we can't find the page you're looking for.</p>
    <TransitionLink
      to="/"
      className="transitionLink transitionLink--home"
      exit={{ length: 0.6 }}
      entry={{ length: 0.6 }}
    ></TransitionLink>
  </Layout>,
]

export default NotFoundPage
